import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

const Container: React.FC<Props> = ({ children }: Props) => {
  return (
    <div className="container mx-auto max-w-5xl px-2 py-2 md:my-8 lg:px-0">
      {children}
    </div>
  );
};

export default Container;
