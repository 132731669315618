import { createContext, PropsWithChildren, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useSessionStorage from "../hooks/use-session-storage";

export interface Auth {
  setToken(token: string): void;
  setProfile(name: string): void;
  logout(): void;
  login(): Promise<void>;
  isAdmin(): boolean;
  isModerator(): boolean;
  hasRole(role: Role): boolean;
  user?: User;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const AuthContext = createContext<Auth>({} as Auth);

export enum Role {
  Admin = "admin",
  Moderator = "moderator",
  User = "user",
}

export interface User {
  name?: string;
  token?: string;
  role?: Role;
}

interface TokenPayload {
  id: string;
  exp: number;
  role: Role;
}

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useSessionStorage<User | undefined>(
    "user",
    undefined,
  );
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const setProfile = (name: string) => {
    setUser({ ...user, name });
    navigate("/downloads");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(undefined);
    navigate("/", { replace: true });
  };

  const setToken = (token: string) => {
    const parts = token.split(".");
    const decoded = JSON.parse(
      Buffer.from(parts[1], "base64").toString(),
    ) as TokenPayload;
    setUser({ ...user, role: decoded.role, token });
    navigate("/downloads");
  };

  const login = async () => {
    const resp = await fetch(
      `${process.env.API_URI as string}/auth/google/login`,
      {
        redirect: "manual",
      },
    );
    if (resp.url) {
      window.location = resp.url as unknown as Location;
    }
  };

  const isAdmin = () => user?.role === "admin";
  const isModerator = () =>
    user?.role === "admin" || user?.role === "moderator";
  const hasRole = (role: Role) => user?.role === "admin" || user?.role === role;

  return (
    <AuthContext.Provider
      value={{
        user,
        setToken,
        setProfile,
        login,
        logout,
        isAdmin,
        isModerator,
        hasRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): Auth => {
  return useContext(AuthContext);
};
