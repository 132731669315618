import React from "react";
import { Navigate } from "react-router-dom";
import { Role, useAuth } from "../providers/auth-provider";

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRole?: Role;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  requiredRole,
}) => {
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  if (requiredRole && user.role !== requiredRole) {
    // user is authenticated but does not have the required role
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedRoute;
