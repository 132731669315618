import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "./use-axios";
import { Role } from "../providers/auth-provider";

export class UserDetails {
  id: string;
  name: string;
  email: string;
  access_token: string;
  social: string;
  social_id: string;
  jellyfin_id: string;
  picture: string;
  paid_until: Date | null;
  role: Role;
  created_at: Date;
  last_login_at: Date | null;

  get isPaid() {
    return this.paid_until && this.paid_until.getTime() >= Date.now();
  }

  pictureUrl(token: string) {
    return `${process.env.API_URI}/api/users/${this.id}/picture?token=${token}`;
  }
}

export function useUserById(id: string) {
  const axios = useAxios();
  return useQuery<UserDetails, Error>(["users", id], async () => {
    const { data } = await axios.get<UserDetails>(`/api/users/${id}`);
    return deserializeUser(data);
  });
}

export function useUsers() {
  const axios = useAxios();
  return useQuery<UserDetails[], Error>(["users"], async () => {
    const { data } = await axios.get<UserDetails[]>(`/api/users`);
    return data.map(deserializeUser);
  });
}

export function useUserMutation() {
  const axios = useAxios();
  const queryClient = useQueryClient();
  return useMutation<UserDetails, Error, Partial<UserDetails>>(
    async user => {
      const { data } = await axios.patch<UserDetails>(
        `/api/users/${user.id}`,
        user,
      );
      return deserializeUser(data);
    },
    {
      onSuccess: data => {
        queryClient.setQueryData(["users", data.id], data);
        queryClient.setQueryData(
          ["users"],
          (users: UserDetails[] | undefined) =>
            users?.map(u => (u.id === data.id ? data : u)),
        );
      },
    },
  );
}

function deserializeUser(user: UserDetails): UserDetails {
  const u = new UserDetails();
  Object.assign(u, user);
  u.created_at = new Date(u.created_at);
  u.paid_until = u.paid_until ? new Date(u.paid_until) : null;
  u.last_login_at = u.last_login_at ? new Date(u.last_login_at) : null;
  return u;
}
