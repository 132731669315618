import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "./use-axios";
import { JellyfinUser } from "../models/jellyfin";

export function useJellyfinUserMutation() {
  const axios = useAxios();
  const queryClient = useQueryClient();
  return useMutation<string, Error, string>(
    async userId => {
      await axios.post<never>(`/api/users/${userId}/jellyfin/new`);
      return userId;
    },
    {
      onSuccess: userId => {
        queryClient.invalidateQueries(["users", userId]);
      },
    },
  );
}

export function useJellyfinUser() {
  const axios = useAxios();
  return useQuery<JellyfinUser, Error>(["jellyfin-user", "me"], async () => {
    const response = await axios.get<JellyfinUser>(`/api/jellyfin/me`);
    return response.data;
  });
}

export function useResetJellyfinPasswordMutation() {
  const axios = useAxios();
  return useMutation<{ newPassword: string }, Error>(async () => {
    const response = await axios.post<{ newPassword: string }>(
      `/api/jellyfin/reset-password`,
    );
    return response.data;
  });
}
