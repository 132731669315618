import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Alert, Toast } from "react-daisyui";

interface ToastApi {
  Add(
    message: string,
    status?: "info" | "success" | "warning" | "error" | undefined,
    icon?: React.ReactNode,
    delay?: number,
  ): void;
}

const ToastContext = createContext<ToastApi | null>(null);

const Expire = ({
  delay,
  children,
}: {
  delay: number;
  children: ReactNode;
}) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, delay);
    return () => clearTimeout(timer);
  }, [delay]);

  return visible ? <div>{children}</div> : <div className="hidden" />;
};

interface IconForStatusProps {
  status: "info" | "success" | "warning" | "error" | undefined;
}

const IconForStatus: React.FC<
  IconForStatusProps & React.HTMLAttributes<HTMLDivElement>
> = ({ status }) => {
  switch (status) {
    case "success":
      return <CheckCircleIcon className="h-6 w-6" />;
    case "warning":
      return <ExclamationTriangleIcon className="h-6 w-6" />;
    case "error":
      return <ExclamationCircleIcon className="h-6 w-6" />;
    default:
      return <InformationCircleIcon className="h-6 w-6" />;
  }
};

export const ToastProvider = ({ children }: PropsWithChildren) => {
  const [alerts, setAlerts] = useState<React.ReactNode[]>([]);
  const [count, setCount] = useState<number>(0);
  const value = useMemo(
    () => ({
      Add(
        message: string,
        status?: "info" | "success" | "warning" | "error" | undefined,
        icon?: React.ReactNode,
        delay?: number,
      ) {
        setAlerts(prev => [
          ...prev,
          <Expire
            delay={delay ?? 5000}
            key={count}
          >
            <Alert
              status={status}
              icon={icon ?? <IconForStatus status={status} />}
            >
              {message}
            </Alert>
          </Expire>,
        ]);
        setCount(prev => prev + 1);
      },
    }),
    [count],
  );
  return (
    <ToastContext.Provider value={value}>
      {children}

      {alerts && (
        <Toast
          vertical="top"
          horizontal="end"
          className="mt-16"
        >
          {alerts}
        </Toast>
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
