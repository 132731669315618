import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "../../components/container";
import Spinner from "../../components/spinner";
import { useAuth } from "../../providers/auth-provider";

export default function AuthCallback() {
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      auth.setToken(token);
      return;
    }

    navigate("/");
  }, [auth, navigate, searchParams]);
  return (
    <>
      <Container>
        <Spinner />
      </Container>
    </>
  );
}
