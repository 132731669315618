import { useQuery } from "@tanstack/react-query";
import { useAxios } from "./use-axios";

interface RemoteConfig {
  PLEX_PUBLIC_BASE_URL: string;
  JELLYFIN_PUBLIC_BASE_URL: string;
}

export function useRemoteConfig() {
  const axios = useAxios();
  return useQuery<RemoteConfig>(["remote-config"], async () => {
    const { data } = await axios.get<RemoteConfig>(`api/config`);
    return data;
  });
}
