import React from "react";
import Container from "../components/container";
import NavBar from "../components/nav-bar";
import {
  useJellyfinUser,
  useResetJellyfinPasswordMutation,
} from "../hooks/use-jellyfin";
import { Button, Card } from "react-daisyui";
import { useRemoteConfig } from "../hooks/use-remote-config";

const JellyfinPage: React.FC = () => {
  const { isLoading, data } = useJellyfinUser();
  const {
    isLoading: isChangingPassword,
    data: passwordData,
    mutate: resetPassword,
  } = useResetJellyfinPasswordMutation();
  const config = useRemoteConfig();
  const baseUrl = config.data?.JELLYFIN_PUBLIC_BASE_URL
    ? new URL(config.data.JELLYFIN_PUBLIC_BASE_URL)
    : null;
  return (
    <>
      <NavBar />
      <Container>
        <Card.Actions className="justify-end">
          <Button
            size="sm"
            tag="a"
            color="primary"
            href={config.data?.JELLYFIN_PUBLIC_BASE_URL}
            target="_blank"
          >
            Open Jellyfin Player
          </Button>
        </Card.Actions>
        {isLoading && <div>Loading...</div>}
        {!isLoading && data && (
          <div className="mt-10 grid gap-3 md:grid-cols-5">
            <div className="font-semibold">Web player</div>
            <div className="flex items-center break-all font-light md:col-span-4">
              <Button
                tag="a"
                href={config.data?.JELLYFIN_PUBLIC_BASE_URL}
                target="_blank"
                color="neutral"
                size="xs"
              >
                {baseUrl?.protocol + "//" + baseUrl?.host}
              </Button>
            </div>
            <div className="font-semibold">Server host</div>
            <div className="flex items-center break-all font-mono font-light md:col-span-4">
              {baseUrl?.host}
            </div>
            <div className="font-semibold">Username</div>
            <div className="flex items-center break-all font-mono font-light md:col-span-4">
              {data.Name}
            </div>
            <div className="font-semibold">Password</div>
            <div className="flex items-center break-all font-light md:col-span-4">
              ask admin for reset
              {!isChangingPassword && passwordData && (
                <div className="mr-8">{passwordData.newPassword}</div>
              )}
              {/* awaiting release of https://github.com/jellyfin/jellyfin/issues/10094 */}
              {/* <Button
                size="sm"
                color="secondary"
                loading={isChangingPassword}
                onClick={e => {
                  e.preventDefault();
                  resetPassword();
                }}
              >
                Reset
              </Button> */}
            </div>
            <div className="font-semibold">Possible clients</div>
            <div className="flex items-center break-all font-light md:col-span-4">
              <Button
                tag="a"
                href="https://jellyfin.org/downloads/"
                target="_blank"
                color="neutral"
                size="xs"
              >
                See downloads
              </Button>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default JellyfinPage;
