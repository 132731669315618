import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./app";
import { AuthProvider } from "./providers/auth-provider";
import AxiosProvider from "./providers/axios-provider";
import { ToastProvider } from "./providers/toast-provider";

import "./index.css";
import "moment/locale/en-gb";
import moment from "moment";
moment.locale("en-gb");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache(),
});

let container: HTMLElement | null = null;

document.addEventListener("DOMContentLoaded", () => {
  container = document.getElementById("app");

  if (container == null) {
    throw new Error("app id not found");
  }

  const app = createRoot(container);
  app.render(
    <StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <AxiosProvider>
            <ToastProvider>
              <QueryClientProvider client={queryClient}>
                <App />
                <ReactQueryDevtools />
              </QueryClientProvider>
            </ToastProvider>
          </AxiosProvider>
        </AuthProvider>
      </BrowserRouter>
    </StrictMode>,
  );
});
