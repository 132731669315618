import React from 'react';

interface Props {
  children?: React.ReactNode;
}

const AsyncLoad: React.FC<Props> = ({ children }) => {
  return <React.Suspense fallback={<>...</>}>{children}</React.Suspense>;
};

export default AsyncLoad;
