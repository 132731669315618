import React, { FormEvent, PropsWithChildren, useState } from "react";
import { Button, Dropdown, Form, Input, Menu, Navbar } from "react-daisyui";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Role, useAuth } from "../providers/auth-provider";

const navItems = [
  { name: "Downloading", href: "/downloads", isActive: false },
  { name: "Movies", href: "/files/movies", isActive: false },
  { name: "Series", href: "/files/series", isActive: false },
  { name: "Others", href: "/files/others", isActive: false },
  { name: "Users", href: "/users", isActive: false, requiredRole: Role.Admin },
  { name: "Jellyfin", href: "/jellyfin", isActive: false },
];

function MenuItems({
  ItemWrapper,
}: {
  ItemWrapper: React.FC<PropsWithChildren>;
}) {
  const auth = useAuth();
  const location = useLocation();
  const navigation = navItems.map(item => ({
    ...item,
    isActive: location.pathname.startsWith(item.href),
  }));
  return (
    <>
      {navigation
        .filter(item => !item.requiredRole || auth.hasRole(item.requiredRole))
        .map(item => (
          <ItemWrapper key={item.name}>
            {item.href.startsWith("https:") ? (
              <a href={item.href}>{item.name}</a>
            ) : (
              <Link
                to={item.href}
                className={item.isActive ? "active" : ""}
              >
                {item.name}
              </Link>
            )}
          </ItemWrapper>
        ))}
    </>
  );
}

function Li({ children }: PropsWithChildren) {
  return <li>{children}</li>;
}

export default function NavBar() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [search, setSearch] = useState<string>(
    decodeURIComponent(params.get("q") ?? ""),
  );
  function doSearch(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    navigate(`/search?q=${encodeURIComponent(search)}`);
  }
  return (
    <Navbar className="md:p-5">
      <Navbar.Start>
        <Dropdown>
          <Dropdown.Toggle
            color="ghost"
            className="lg:hidden"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu
            tabIndex={0}
            className="menu-sm z-[1] mt-3 w-52"
          >
            <MenuItems ItemWrapper={Li} />
          </Dropdown.Menu>
        </Dropdown>
        <Link
          className="btn btn-ghost text-xl normal-case"
          to="/"
        >
          all4me
        </Link>
      </Navbar.Start>
      <Navbar.Center className="hidden lg:flex">
        <Menu
          horizontal
          className="gap-2 p-0"
        >
          <MenuItems ItemWrapper={Menu.Item} />
        </Menu>
      </Navbar.Center>
      <Navbar.End>
        <div className="form-control">
          <Form onSubmit={doSearch}>
            <Input
              bordered
              type="text"
              placeholder="Search"
              value={search}
              onChange={ev => setSearch(ev.currentTarget.value)}
            />
          </Form>
        </div>
      </Navbar.End>
    </Navbar>
  );
}
