import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import AsyncLoad from "./components/async-load";
import ProtectedRoute from "./components/protected-route";
import AuthCallback from "./pages/auth/callback";
import { Role, useAuth } from "./providers/auth-provider";
import UsersPage from "./pages/users";
import JellyfinPage from "./pages/jellyfin";

const LoginPage = lazy(async () => await import("./pages/auth/login"));
const FilesPage = lazy(async () => await import("./pages/files"));
const FilePage = lazy(async () => await import("./pages/file"));
const SearchPage = lazy(async () => await import("./pages/search"));
const DownloadsPage = lazy(async () => await import("./pages/downloads"));

export default function App() {
  const auth = useAuth();
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AsyncLoad>
            {!auth.user && <LoginPage />}
            {auth.user && (
              <ProtectedRoute>
                <DownloadsPage />
              </ProtectedRoute>
            )}
          </AsyncLoad>
        }
      />
      <Route
        path="/files/:path"
        element={
          <AsyncLoad>
            <ProtectedRoute>
              <FilesPage />
            </ProtectedRoute>
          </AsyncLoad>
        }
      />
      <Route
        path="/file/:path"
        element={
          <AsyncLoad>
            <ProtectedRoute>
              <FilePage />
            </ProtectedRoute>
          </AsyncLoad>
        }
      />
      <Route
        path="/search"
        element={
          <AsyncLoad>
            <ProtectedRoute>
              <SearchPage />
            </ProtectedRoute>
          </AsyncLoad>
        }
      />
      <Route
        path="/downloads"
        element={
          <AsyncLoad>
            <ProtectedRoute>
              <DownloadsPage />
            </ProtectedRoute>
          </AsyncLoad>
        }
      />
      <Route
        path="/users"
        element={
          <AsyncLoad>
            <ProtectedRoute requiredRole={Role.Admin}>
              <UsersPage />
            </ProtectedRoute>
          </AsyncLoad>
        }
      />
      <Route
        path="/jellyfin"
        element={
          <AsyncLoad>
            <ProtectedRoute>
              <JellyfinPage />
            </ProtectedRoute>
          </AsyncLoad>
        }
      />
      <Route
        path="/auth/callback"
        element={<AuthCallback />}
      />
      <Route
        path="*"
        element={<div>Page not found</div>}
      />
    </Routes>
  );
}
