import React from "react";
import NavBar from "../components/nav-bar";
import Container from "../components/container";
import { UserDetails, useUserMutation, useUsers } from "../hooks/use-user";
import { Avatar, Badge, Button, Card } from "react-daisyui";
import moment from "moment";
import { useAuth } from "../providers/auth-provider";
import { useJellyfinUserMutation } from "../hooks/use-jellyfin";

const UsersPage: React.FC = () => {
  const { data: users, isLoading } = useUsers();
  const { user: authUser } = useAuth();
  const { mutate: mutateUser } = useUserMutation();
  const { mutate: mutateJellyfinUser } = useJellyfinUserMutation();

  const expiryState = (user: UserDetails) => {
    if (user.paid_until == null || moment(user.paid_until).isBefore()) {
      return "error";
    }
    if (moment(user.paid_until).isAfter(moment().add(2, "month"))) {
      return "success";
    }
    return "warning";
  };

  const paidUntil = (
    user: UserDetails,
    n: number,
    uot: moment.unitOfTime.DurationConstructor,
  ) => {
    mutateUser({
      id: user.id,
      paid_until: moment().add(n, uot).toDate(),
    });
  };

  const createJellyfinUser = (user: UserDetails) => {
    mutateJellyfinUser(user.id);
  };

  return (
    <>
      <NavBar />
      <Container>
        {isLoading && <div>Loading...</div>}
        {!isLoading && users && (
          <div>
            {users.map(user => (
              <Card key={user.id}>
                <Card.Body>
                  <Card.Title>
                    <div className="flex flex-1 flex-col items-center justify-center gap-5 md:flex-row md:justify-start">
                      {authUser && authUser.token && user.picture != "" && (
                        <Avatar
                          src={user.pictureUrl(authUser.token)}
                          size="md"
                          shape="circle"
                          borderColor="primary"
                          border
                        />
                      )}{" "}
                      {user.name}
                    </div>
                  </Card.Title>
                  <div className="grid md:grid-cols-5">
                    {Object.keys(user)
                      .filter(k => !["picture", "access_token"].includes(k))
                      .map(key => {
                        const exp = expiryState(user);
                        return (
                          <React.Fragment key={key}>
                            <div className=" font-semibold">{key}</div>
                            <div className="flex items-center break-all font-light md:col-span-4">
                              {user[key] != null
                                ? user[key] instanceof Date
                                  ? moment(user[key]).format("LLL")
                                  : user[key]
                                : "-"}
                              {key === "paid_until" && (
                                <Badge
                                  className="ml-2"
                                  color={exp}
                                >
                                  {exp === "success"
                                    ? "Paid"
                                    : exp === "warning"
                                    ? "Expiring"
                                    : "Expired"}
                                </Badge>
                              )}
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </div>
                  <Card.Actions className="justify-end">
                    <Button
                      size="sm"
                      color="secondary"
                      onClick={e => {
                        e.preventDefault();
                        paidUntil(user, 1, "month");
                      }}
                    >
                      Paid 1 month
                    </Button>
                    <Button
                      size="sm"
                      color="secondary"
                      onClick={e => {
                        e.preventDefault();
                        paidUntil(user, 1, "year");
                      }}
                    >
                      Paid 1 year
                    </Button>
                    <Button
                      size="sm"
                      color="secondary"
                      onClick={e => {
                        e.preventDefault();
                        paidUntil(user, 0, "days");
                      }}
                    >
                      Unpaid
                    </Button>
                    {!user.jellyfin_id.length && (
                      <Button
                        size="sm"
                        color="secondary"
                        onClick={e => {
                          e.preventDefault();
                          createJellyfinUser(user);
                        }}
                      >
                        Create Jellyfin user
                      </Button>
                    )}
                  </Card.Actions>
                </Card.Body>
              </Card>
            ))}
          </div>
        )}
      </Container>
    </>
  );
};

export default UsersPage;
