import axios, { AxiosError, AxiosInstance } from "axios";
import { createContext, useMemo } from "react";
import { useAuth } from "./auth-provider";

export const AxiosContext = createContext<AxiosInstance | null>(null);
export const api = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: process.env.API_URI as string,
});

export default function AxiosProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const { user, logout } = useAuth();
  const apiMemo = useMemo(() => {
    api.interceptors.request.use(async config => {
      // Read token for anywhere, in this case directly from localStorage
      if (user?.token != null) {
        if (config.headers == null) {
          config.headers = {};
        }
        config.headers.Authorization = `Bearer ${user.token}`;
      }

      return config;
    });

    api.interceptors.response.use(undefined, async (error: AxiosError) => {
      if (error.response?.status === 401) {
        logout();
      }
      if (error.message != null) {
        throw new Error(error.message);
      }
      throw error;
    });

    return api;
  }, [logout, user?.token]);

  return (
    <AxiosContext.Provider value={apiMemo}>{children}</AxiosContext.Provider>
  );
}
